import React, { Fragment, useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import VideoPreviewContainer from '../../../containers/videoPreview';
import SCORMPreviewContainer from '../../../containers/scormPreview';
import ThemedNotePlaceholder from '../../themed/ThemedNotePlaceholder';
import {
    FlexDiv,
    Heading1,
    Heading2,
    Heading3,
    TextLead,
    TextDefault,
    Block,
    ThemedButton,
    ModalBody,
} from '@awarego/awarego-components';
import ThemedSpinner from '../../themed/ThemedSpinner';
import sanitizeHtml from 'sanitize-html';
import pluralize from 'pluralize';
import SubjectNoteEditor from '../../manager/catalog/subjectNoteEditor';
import { SUBJECT_TYPE } from '../../../utils/constants';
import SubjectQuiz from './subjectQuiz';

function Subject({
    subject,
    showBackLink = true,
    editNoteId,
    onNoteChange,
    onAddNote,
    onEditNote,
    onDeleteNote,
    cancelEditNote,
    onSaveNote,
    language,
}) {
    let note =
        subject &&
        subject.notes &&
        subject.notes.find((x) => x.id === editNoteId);
    let noteValue = note && note.content;

    const width = useMemo(() => {
        if (subject && subject.definition && subject.definition.width) {
            return subject.definition.width;
        }
        return 800;
    });
    const height = useMemo(() => {
        if (subject && subject.definition && subject.definition.height) {
            return subject.definition.height;
        }
        return 600;
    });

    const existingTrainingsBlock = () => {
        return (
            <Block resetmargin>
                <Heading3 mb={8} displayBlock>
                    Note:
                </Heading3>
                <TextDefault>
                    This subject is already included in the following{' '}
                    {pluralize('training', subject.trainings.length)}:
                </TextDefault>
                <FlexDiv column ml={16}>
                    {subject.trainings.map((training) => {
                        return (
                            <Link
                                key={training.id}
                                to={`/trainings/${training.id}/view`}
                            >
                                <TextDefault>• {training.name}</TextDefault>
                            </Link>
                        );
                    })}
                </FlexDiv>
            </Block>
        );
    };

    const subjectNoteEditor = () => {
        return (
            <Fragment>
                {subject.notes &&
                    subject.notes.map((note) => {
                        return (
                            <div
                                className="subject-optional-note"
                                key={note.id}
                            >
                                <div className="subject-optional-note-actions">
                                    <div
                                        className="btn edit"
                                        onClick={() => {
                                            onEditNote(note.id);
                                        }}
                                    ></div>
                                    <div
                                        className="btn delete-bin"
                                        onClick={() => {
                                            onDeleteNote(subject, note.id);
                                        }}
                                    ></div>
                                </div>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizeHtml(note.content),
                                    }}
                                ></div>
                            </div>
                        );
                    })}
                <br />
                {editNoteId != null ? (
                    <Fragment>
                        <SubjectNoteEditor
                            initialValue={noteValue}
                            onChange={onNoteChange}
                        />
                        <FlexDiv my={16} justifyEnd>
                            <ThemedButton
                                mode="secondary"
                                text="Cancel"
                                onClick={cancelEditNote}
                            />
                            <ThemedButton
                                mode="primary"
                                text="Save"
                                $airy
                                onClick={() => {
                                    onSaveNote(subject);
                                }}
                            />
                        </FlexDiv>
                    </Fragment>
                ) : (
                    <ThemedNotePlaceholder
                        title="Add additional information"
                        description="Additional instructions for your employees, links to relevant resources etc."
                        onClick={onAddNote}
                    />
                )}
                <hr />
            </Fragment>
        );
    };

    if (subject) {
        return (
            <>
                {showBackLink && (
                    <Link to={`/catalog/`}>
                        <div className={'navigation back-button'}>
                            Back to subjects
                        </div>
                    </Link>
                )}

                {subject.trainings &&
                    subject.trainings.length > 0 &&
                    existingTrainingsBlock()}

                <ModalBody>
                    <FlexDiv justifyCenter>
                        <div style={{ maxWidth: width, width: '100%' }}>
                            <Heading1 displayBlock my={16}>
                                {subject.title}
                            </Heading1>
                            <TextLead>{subject.description}</TextLead>

                            {(subject.type === SUBJECT_TYPE.VIDEO ||
                                subject.type === SUBJECT_TYPE.DECISION) && (
                                <>
                                    {subjectNoteEditor()}
                                    <VideoPreviewContainer
                                        previewVideoLocation={subject.id}
                                        modal={false}
                                        isOpen={true}
                                        subject={subject}
                                        language={language}
                                    />
                                    <SubjectQuiz subject={subject} />
                                    {subject.content && (
                                        <>
                                            <Heading2 my={16} displayBlock>
                                                Further reading
                                            </Heading2>
                                            <FlexDiv
                                                className={
                                                    'subject-section-text'
                                                }
                                                dangerouslySetInnerHTML={{
                                                    __html: sanitizeHtml(
                                                        subject.content
                                                    ),
                                                }}
                                            />
                                        </>
                                    )}
                                </>
                            )}
                            {subject.type === SUBJECT_TYPE.SCORM && (
                                <>
                                    {subjectNoteEditor()}
                                    <SCORMPreviewContainer
                                        previewScormLocation={subject.id}
                                        definition={subject.definition}
                                        modal={false}
                                        language={language}
                                    />
                                </>
                            )}
                        </div>
                    </FlexDiv>
                </ModalBody>
            </>
        );
    } else {
        return (
            <div className="videos">
                <Row>
                    <Col md="12" xs="12" className="leftMenu">
                        <ThemedSpinner />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default observer(Subject);
