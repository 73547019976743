import React, { useState, createRef } from 'react';
import { observer } from 'mobx-react';
import Subject from '../components/manager/catalog/subject';
import SubjectStore from '../stores/models/subjectStore';
import { SUBJECT_TYPE } from '../utils/constants';

import { fixHtml } from '../utils/helpers';
import { useStores } from '../hooks/use-stores';
import { useParams } from 'react-router-dom';

function SubjectContainer({
    companyId,
    subject = null,
    categories,
    showBackLink = true,
    language,
}) {
    const { id } = useParams();
    const { commonStore, catalogStore } = useStores();

    const [editNoteId, setEditNoteId] = useState(null);

    const [editValue, setEditValue] = useState('');

    const onAddNote = () => {
        setEditNoteId(-1);
    };

    const onEditNote = (editNoteId) => {
        setEditNoteId(editNoteId);
    };

    const onSaveNote = async (subject) => {
        let result = await catalogStore.saveNote(
            companyId,
            subject.id,
            editNoteId,
            fixHtml(editValue),
            subject,
            language
        );
        if (result) {
            commonStore.success('Note saved.');
            cancelEditNote();
        } else {
            if (catalogStore.error) {
                commonStore.error(catalogStore.error);
            }
        }
    };

    const onDeleteNote = async (subject, id) => {
        commonStore.showConfirm(
            'Are you sure you want to delete this note?',
            'Delete',
            'Delete note',
            async () => {
                let result = await catalogStore.deleteNote(
                    companyId,
                    subject.id,
                    id,
                    subject
                );
                if (result) {
                    commonStore.success('Note deleted.');
                } else {
                    if (catalogStore.error) {
                        commonStore.error(catalogStore.error);
                    }
                }
            },
            true
        );
    };

    const cancelEditNote = () => {
        setEditNoteId(null);
    };

    const getSubject = () => {
        let resolvedSubject = null;
        if (subject) {
            resolvedSubject = subject;
        } else {
            if (categories && categories.length > 0) {
                for (let i = 0; i < categories.length && !subject; i++) {
                    if (
                        categories[i].subjects &&
                        categories[i].subjects.length > 0
                    ) {
                        for (
                            let a = 0;
                            a < categories[i].subjects.length;
                            a++
                        ) {
                            if (categories[i].subjects[a].id === id) {
                                resolvedSubject = categories[i].subjects[a];
                            }
                        }
                    }
                }
            }
        }
        if (resolvedSubject && resolvedSubject.type === SUBJECT_TYPE.DECISION) {
            resolvedSubject = new SubjectStore(resolvedSubject);
        }

        return resolvedSubject;
    };

    const derivedSubject = getSubject();

    return (
        <Subject
            subject={derivedSubject}
            showBackLink={showBackLink}
            editNoteId={editNoteId}
            onAddNote={onAddNote}
            onEditNote={onEditNote}
            onDeleteNote={onDeleteNote}
            onNoteChange={setEditValue}
            cancelEditNote={cancelEditNote}
            onSaveNote={onSaveNote}
            language={language}
        />
    );
}

export default observer(SubjectContainer);
