import { observer } from 'mobx-react-lite';
import React, { useState, useEffect, useCallback } from 'react';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { Block, FlexDiv } from '@awarego/awarego-components';
import HeaderSection from '../../../components/header/HeaderSection';
import ScormContentHeader from './ScormContentHeader';
import ScormLanguagesTable from './ScormLanguagesTable';
import { useStores } from '../../../hooks/use-stores';
import ScormContentModal from '../ScormContentModal';
import SubjectPreview from '../../subjectPreview';
import NoItemsYetContent from '../../../components/manager/no-items-yet-content';
import { useNavigate, Link, useParams } from 'react-router-dom';
function ScormContentView() {
    const { store, commonStore, cmi5Store } = useStores();
    const { currentCompany } = store;
    const { scormId } = useParams();
    const [mainScorm, setMainScorm] = useState(null);
    const [targetPackage, setTargetPackage] = useState(null);
    const [isEditLanguageModalOpen, setEditLanguageModalOpen] = useState(false);
    const [isAddLanguageModalOpen, setAddLanguageModalOpen] = useState(false);
    const [isPreviewLanguageModalOpen, setPreviewLanguageModalOpen] =
        useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        loadData();
    }, [currentCompany.company_id, scormId]);

    const loadData = useCallback(async () => {
        await cmi5Store.loadScormContent(currentCompany.company_id, scormId);

        if (
            cmi5Store.scormWithTranslations &&
            cmi5Store.scormWithTranslations.length > 0
        ) {
            // scorm translation with the lowest id is the first and main scorm
            const sortedById = cmi5Store.scormWithTranslations.sort(
                (a, b) => a.id - b.id
            );
            setMainScorm(sortedById[0]);
        }
    }, [currentCompany.company_id, scormId]);

    const deleteLanguage = async (e, languagePackage) => {
        e.stopPropagation();
        const isDefaultLanguage = languagePackage.id == mainScorm.id;
        const prompt = isDefaultLanguage
            ? 'Deleting default language will delete the whole scorm package. Are you sure you want to proceed?'
            : 'Are you sure you want to delete this language package?';
        commonStore.showConfirm(
            prompt,
            'Delete',
            `Delete ${isDefaultLanguage ? 'Default ' : ' '}Language`,
            async () => {
                const result = await cmi5Store.deleteLanguagePackage(
                    currentCompany.company_id,
                    languagePackage.subject_id,
                    languagePackage.language,
                    isDefaultLanguage
                );
                if (result) {
                    if (isDefaultLanguage) {
                        commonStore.success('Scorm package deleted!');
                        navigate('/content');
                    } else {
                        commonStore.success('Language package deleted!');
                    }
                }
            },
            true
        );
    };

    return (
        <Container className="manager-content">
            <Helmet>
                <title>Scorm Content</title>
            </Helmet>

            <Link to={`/content`}>
                <div className={'navigation back-button'}>Back to content</div>
            </Link>

            {cmi5Store.loadingScorm || mainScorm ? (
                <>
                    <ScormContentHeader
                        companyId={currentCompany.company_id}
                        scormId={scormId}
                        scorm={mainScorm}
                        loadingData={cmi5Store.loadingScorm}
                    />

                    <Block tablePadding>
                        <HeaderSection
                            heading={'Languages'}
                            excludeBlock
                            marginLeft="16"
                        />

                        <ScormLanguagesTable
                            onEditLanguage={(languagePackage) => {
                                setTargetPackage(languagePackage);
                                setEditLanguageModalOpen(true);
                            }}
                            onDeleteLanguage={deleteLanguage}
                            onAddLanguage={() => setAddLanguageModalOpen(true)}
                            onPreviewLanguage={(languagePackage) => {
                                setTargetPackage(languagePackage);
                                setPreviewLanguageModalOpen(true);
                            }}
                            mainScorm={mainScorm}
                        ></ScormLanguagesTable>

                        {/* Edit Language Modal */}
                        {targetPackage && (
                            <ScormContentModal
                                isModalOpen={isEditLanguageModalOpen}
                                onRequestCloseModal={(newName) => {
                                    cmi5Store.error = null;
                                    setEditLanguageModalOpen(false);
                                    if (newName) {
                                        mainScorm.name = newName;
                                    }
                                    setTargetPackage(null);
                                }}
                                companyId={currentCompany.company_id}
                                isEditLanguageContent={true}
                                targetPackage={targetPackage}
                            />
                        )}

                        {/* Add Language Modal */}
                        {mainScorm && (
                            <ScormContentModal
                                isModalOpen={isAddLanguageModalOpen}
                                onRequestCloseModal={() => {
                                    cmi5Store.error = null;
                                    setAddLanguageModalOpen(false);
                                }}
                                companyId={currentCompany.company_id}
                                isAddLanguageContent={true}
                                targetPackage={mainScorm}
                            />
                        )}

                        {/* Language preview modal*/}
                        {targetPackage && (
                            <SubjectPreview
                                subjectId={targetPackage.subject_id}
                                isOpen={isPreviewLanguageModalOpen}
                                onClose={() => {
                                    setPreviewLanguageModalOpen(false);
                                    setTargetPackage(null);
                                }}
                                hideFooter={true}
                                showLanguageSelector={true}
                                availableLanguages={[targetPackage.language]}
                                defaultLanguage={targetPackage.language}
                                subjectTitle={targetPackage.name}
                            />
                        )}
                    </Block>
                </>
            ) : (
                <Block>
                    {' '}
                    <FlexDiv justifyCenter>
                        <NoItemsYetContent
                            img={
                                '/static/img/recurring-reports-inside-frame.svg'
                            }
                            imgAlt={'empty content view'}
                            title={'There is no content to display'}
                        />
                    </FlexDiv>
                </Block>
            )}
        </Container>
    );
}

export default observer(ScormContentView);
